import type { Country } from "src/types"
import { GlucoseUnit } from "src/types"

// TODO this will be removed when we have an endpoint for the country constants
// countries indexed by their alpha-2 iso code
export const countries: Record<string, Country> = {
  as: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  at: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  au: {
    is_supported: false,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  be: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  br: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  ca: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  ch: {
    is_supported: true,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  cl: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  cz: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  de: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  dk: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  ee: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  es: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  fi: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  fr: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  gb: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  gu: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  hk: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  hr: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  hu: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  ie: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: false,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  il: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  it: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  kr: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  lt: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  lu: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  lv: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  mp: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  mt: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: false,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  mx: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  nl: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  no: {
    is_supported: true,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  nz: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  ph: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  pl: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  pr: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  pt: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  se: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  sg: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  um: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  us: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  vi: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  za: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
}
